import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'
import { useRoute } from '@nuxtjs/composition-api'

export function fieldComponentName(field) {
  return upperFirst(camelCase(`Formie ${field.__typename}`))
}

export function fieldVisible(field) {
  return !['disabled', 'hidden'].includes(field.visibility)
}

export function fieldName(field, parentField) {
  return `__${field.__typename}${parentField ? `__${parentField.handle}` : ''}__${field.handle}`
}

const labelPositionMap = {
  'verbb\\formie\\positions\\AboveInput': 'before',
  'verbb\\formie\\positions\\BelowInput': 'after',
  'verbb\\formie\\positions\\Hidden': 'hidden',
}

export function fieldLabelPosition(settings, field, fallback = 'before') {
  return labelPositionMap[field.labelPosition] || labelPositionMap[settings.defaultLabelPosition] || fallback
}

const helpPositionMap = {
  'verbb\\formie\\positions\\AboveInput': 'before',
  'verbb\\formie\\positions\\BelowInput': 'after',
}

export function fieldHelpPosition(settings, field, fallback = 'before') {
  return helpPositionMap[field.instructionsPosition] || helpPositionMap[settings.defaultInstructionsPosition] || fallback
}

export function fieldValidationArray(field) {
  return [field.required ? ['^required', 'trim'] : ['optional']]
}

export function fieldTextValidationArray(field) {
  // todo: minType and maxType can be 'characters' or 'words', we do not yet support 'words'
  // todo: Formie currently never returns minLength or minType
  return [
    field.minLength && field.minType === 'characters' ? ['min', field.minLength, 'length'] : undefined,
    field.maxLength && field.maxType === 'characters' ? ['max', field.maxLength, 'length'] : undefined,
  ].filter((validation) => validation)
}

export function fieldNumberValidationArray(field) {
  const minValue = typeof field.minValue === 'number' ? field.minValue : typeof field.minValue === 'string' ? parseInt(field.minValue, 10) : undefined
  const maxValue = typeof field.maxValue === 'number' ? field.maxValue : typeof field.maxValue === 'string' ? parseInt(field.maxValue, 10) : undefined
  return [
    ['number'],
    field.limit && typeof minValue === 'number' && typeof maxValue === 'number' ? ['between', minValue, maxValue] : undefined,
    field.limit && typeof minValue === 'number' && typeof maxValue !== 'number' ? ['min', minValue] : undefined,
    field.limit && typeof minValue !== 'number' && typeof maxValue === 'number' ? ['max', maxValue] : undefined,
  ].filter((value) => value !== undefined)
}

export function fieldOptions(field) {
  return Object.fromEntries(field.options.map(({ label, value }) => [value, label]))
}

export function fieldDefaultOptions(field) {
  return field.options.filter(({ isDefault }) => isDefault).map(({ value }) => value)
}

export function fieldHiddenDefaultValue(field) {
  switch (field.defaultOption) {
    case 'currentUrl':
      return useRoute().value?.fullPath

    case 'currentUrlNoQueryString':
      return useRoute().value?.path

    default:
      return field.defaultValue
  }
}
